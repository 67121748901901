import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/BlogLayout.js";
import { Helmet } from 'react-helmet';
import { CheckAnim, SunAnim, WheelAnim } from '../components/Animations/SvgAnimations';
import { SectionPageWrapper } from '../components/layouts';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Helmet mdxType="Helmet">
  <title itemProp="name" lang="en">
    How I Work
  </title>
  <meta name="description" content="My coaching process" />
    </Helmet>
    <SectionPageWrapper mdxType="SectionPageWrapper">
      <h1>{`How I Work`}</h1>
      <hr></hr>
      <p>{`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem debitis, deleniti dignissimos dolorum fuga minus, nam nostrum officiis perspiciatis quas, sunt unde voluptatibus. Aspernatur blanditiis laboriosam magnam quam vero!`}</p>
      <p>{`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem debitis, deleniti dignissimos dolorum fuga minus, nam nostrum officiis perspiciatis quas, sunt unde voluptatibus. Aspernatur blanditiis laboriosam magnam quam vero!`}</p>
      <p>{`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem debitis, deleniti dignissimos dolorum fuga minus, nam nostrum officiis perspiciatis quas, sunt unde voluptatibus. Aspernatur blanditiis laboriosam magnam quam vero!`}</p>
      <p>{`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem debitis, deleniti dignissimos dolorum fuga minus, nam nostrum officiis perspiciatis quas, sunt unde voluptatibus. Aspernatur blanditiis laboriosam magnam quam vero!`}</p>
      <h2>{`Steps we'll take together`}</h2>
      <hr></hr>
      <h2>{`Clarify`}</h2>
      <SunAnim mdxType="SunAnim" />
      <p>{`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto cupiditate, doloremque dolorum hic, illo ipsum iure labore, magni modi nemo omnis qui quos ut. Consequuntur ducimus esse rem vel? Obcaecati?`}</p>
      <p>{`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem debitis, deleniti dignissimos dolorum fuga minus, nam nostrum officiis perspiciatis quas, sunt unde voluptatibus. Aspernatur blanditiis laboriosam magnam quam vero!`}</p>
      <h2>{`Develop`}</h2>
      <WheelAnim mdxType="WheelAnim" />
      <p>{`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto cupiditate, doloremque dolorum hic, illo ipsum iure labore, magni modi nemo omnis qui quos ut. Consequuntur ducimus esse rem vel? Obcaecati?`}</p>
      <p>{`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem debitis, deleniti dignissimos dolorum fuga minus, nam nostrum officiis perspiciatis quas, sunt unde voluptatibus. Aspernatur blanditiis laboriosam magnam quam vero!`}</p>
      <h2>{`Prepare`}</h2>
      <CheckAnim mdxType="CheckAnim" />
      <p>{`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto cupiditate, doloremque dolorum hic, illo ipsum iure labore, magni modi nemo omnis qui quos ut. Consequuntur ducimus esse rem vel? Obcaecati?`}</p>
      <p>{`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem debitis, deleniti dignissimos dolorum fuga minus, nam nostrum officiis perspiciatis quas, sunt unde voluptatibus. Aspernatur blanditiis laboriosam magnam quam vero!`}</p>
    </SectionPageWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      